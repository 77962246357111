






























import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableActionButton from '@/@core/components/table/KTableActionButton.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { useMetaModel } from './model';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
// import MetaForm from '../../ui/Meta-form.vue';
// @ts-ignore
import MetaForm from '../../ui/meta-form.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTableWrapper,
    KTextInput,
    KTableSearch,
    KTableActionButton,
    KTableActions,
    KDialog,
    MetaForm
  },
  name: 'MetaDefaultPage',
  setup() {
    const model = useMetaModel()
    const renderKey = ref(0)

    onMounted(async () => {
      await model.onGetManyMeta();
      if (meta.value) formMetaData.value = {...meta.value}

      renderKey.value ++
      
    });

    const meta = computed(() => model.store?.metas?.length ? model.store.metas[0] : {})
    const formMetaData = ref<any>(null)

    return {
      isMobile,
      meta,
      renderKey,
      formMetaData,
      ...useMetaModel()
    };
  }
});
